<div *ngIf="(viewModel$ | async) as viewModel" class="py-3 fx-column fx-gap-sm">

    <p class="mt-3">Add Multimedia Link</p>
    <lc-multi-media-item-form idPrefix="add" [formGroup]="addFormGroup" />

    <button class="fx-row x-end" color="primary" id="multimediaitem-add-link" mat-raised-button type="button" [disabled]="!addFormGroup.valid" [style.width]="'160px'"
            (click)="addLink(viewModel.photoOrder)">Submit Multimedia
    </button>

    <lc-multimedia-table *ngIf="viewModel?.multimediaLinks?.length" deleteEnabled="false" editEnabled="false" selectEnabled="false" [showHeaders]="true" [(items)]="viewModel.multimediaLinks" />

</div>
