<div class="m-4 fx-row x-center">
  <div *ngIf="viewModel$ | async as viewModel" class="fx-column fx-0-1" [style.width]="'1400px'">

    <h1>Photography Appointment Requests for {{viewModel.agencyName}}</h1>

    <lc-order-filters *ngIf="areas$ | async as areas" [areas]="areas" [form]="filtersForm" (filter)="onFilter()" />

    <h5>Appointment Requests</h5>

    <div class="table-container mat-elevation-z1">
      <div *ngIf="isLoading" class="loading-overlay" >
        <mat-spinner />
      </div>

      <div class="table-wrapper">
        <table class="w-100" id="appointments-table" mat-table matSortActive="date"
          matSortDirection="desc" matSortDisableClear [dataSource]="viewModel.photoOrderViewModels">

          <!-- Order Number Column-->
          <ng-container matColumnDef="order-number">
            <th *matHeaderCellDef mat-header-cell>Order Number</th>
            <td *matCellDef="let vm" mat-cell>
              {{ vm.photoOrder.photoOrderId }}
            </td>
          </ng-container>

          <!-- Order Date Column-->
          <ng-container matColumnDef="order-date">
            <th *matHeaderCellDef mat-header-cell>Order Date</th>
            <td *matCellDef="let vm" mat-cell>
              {{ vm.photoOrder.orderedAt | date:'shortDate' }}
            </td>
          </ng-container>

          <!-- Agent Column-->
          <ng-container matColumnDef="agent">
            <th *matHeaderCellDef mat-header-cell>Agent</th>
            <td *matCellDef="let vm" mat-cell>
              {{ vm.photoOrder.agentName }}
            </td>
          </ng-container>

          <!-- Scheduled Date Column-->
          <ng-container matColumnDef="scheduled-date">
            <th *matHeaderCellDef mat-header-cell>Scheduled</th>
            <td *matCellDef="let vm" mat-cell>
              <ng-container *ngIf="!vm.photoOrder?.appointment?.photoAgency?.useCustomScheduling; else customScheduling">
                {{ vm.photoOrder.appointment.scheduledAt | date:'short' : vm.photoOrder.appointment?.timezoneOffset}} {{vm.photoOrder.appointment?.timezone}}
              </ng-container>
              <ng-template #customScheduling>
                <span>Scheduled Externally</span>
              </ng-template>
            </td>
          </ng-container>

          <!-- Address Column-->
          <ng-container matColumnDef="address">
            <th *matHeaderCellDef mat-header-cell>Property Address</th>
            <td *matCellDef="let vm" mat-cell>
              {{ vm.photoOrder.propertyAddress | address }}
            </td>
          </ng-container>

          <!-- Brand Column-->
          <ng-container matColumnDef="brand">
            <th *matHeaderCellDef mat-header-cell>Agent Brand</th>
            <td *matCellDef="let vm" mat-cell>
              {{ vm.photoOrder.brandCode ?? '' }}
            </td>
          </ng-container>

          <!-- Area Column-->
          <ng-container matColumnDef="area">
            <th *matHeaderCellDef mat-header-cell>Agent Area</th>
            <td *matCellDef="let vm" mat-cell>
              {{ vm.photoOrder.area ?? '' }}
            </td>
          </ng-container>

          <!-- Status Column-->
          <ng-container matColumnDef="status">
            <th *matHeaderCellDef  mat-header-cell>Status</th>
            <td *matCellDef="let vm"  mat-cell [matTooltip]= "vm.photoOrder.audit.updatedAt | date:'medium'">
              {{ vm.photoOrder.status | photoOrderStatus }}
            </td>
          </ng-container>

          <!-- Actions Column-->
          <ng-container matColumnDef="action">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let vm" mat-cell>
               <!-- Menu Button -->
              <button *ngIf="vm.hasActions" aria-label="Options" class="photo-order-menu" color="default" mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>

              <!-- Dropdown Menu -->
              <mat-menu #menu="matMenu" >
                <button *ngIf="vm.canStart" id="start-order" mat-menu-item (click)="start(vm.photoOrder)">
                  <mat-icon class="start">forward</mat-icon>
                  <span>Start Order</span>
                </button>
                <button *ngIf="vm.canComplete" id="complete-order" mat-menu-item (click)="complete(vm.photoOrder)">
                  <mat-icon class="cancel">done_all</mat-icon>
                  <span>Complete Order</span>
                </button>
                <button *ngIf="vm.canCancel"  id="cancel-order" mat-menu-item (click)="cancel(vm.photoOrder)">
                  <mat-icon class="cancel">block</mat-icon>
                  <span>Cancel Order</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="columns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: columns;" mat-row [ngClass]="row.canClick ? 'clickable' : 'click-disabled'" (click)="onClick(row)" ></tr>
        </table>
      </div>
      <mat-paginator [length]="pageRequestForm?.totalCount" [pageIndex]="pageRequestForm?.pageNumber?.value - 1" [pageSize]="pageRequestForm?.pageSize?.value" [pageSizeOptions]="pageRequestForm?.pageSizeOptions" (page)="onPageChanged($event)" />
    </div>

    <div *ngIf="viewModel.hasMultipleAgencies" class="my-4">
      <button id="back-button" mat-raised-button type="submit" [routerLink]="['/']">Back to Photographers</button>
    </div>
  </div>
</div>
