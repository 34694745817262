<div class="p-4 fx-row x-center">
  <!-- 810 pixels supports 6 cards with their margins (135px each)-->
  <div class="fx-column fx-0-1" [style.maxWidth]="'810px'" [style.width]="'810px'">
      <h1 id="select-photographer-header">Please select your photographer</h1>

      <lc-card-options valuePath="name" [options]="cardOptions$" (selected)="onSelected($event)" />

      <div class="fx-row">
        <button color="primary" id="next-button" mat-raised-button type="submit" [disabled]="!selectedPhotographer" (click)="onSubmit()">Next</button>
      </div>
  </div>
</div>
