<div class="row mt-3">
  <div class="col-sm-4 col-md-6 col-lg-6">
    <p *ngIf="appService.isPhotoApp">Upload Photos <b>(Max {{ MAX_PHOTOGRAPHER_PHOTOS_COUNT }} photos)</b></p>
    <p *ngIf="!appService.isPhotoApp">Upload Photos</p>
  </div>
</div>
<span>Photos must be JPEG or PNG format.</span>
<span *ngIf="photoColorValidation$ | async"> JPEG images must use the sRGB Color Profile.</span>

<div class="drop-zone">
  <ngx-file-drop customstyle="mb-4 lc-drop-zone" (onFileDrop)="handleDroppedFiles($event)">
    <ng-template ngx-file-drop-content-tmp>

      <div *ngIf="!isProcessing" class="image-margin">
        <div class="row ml-2">
          <div class="col-xl-12 align-content-center"><img alt="upload listing images"
              src="/assets/images/upload-photos.png"></div>
        </div>
        <div class="row">
          <div class="col-xl-12 align-content-center top-horiz-shim-8px">
            <input #photoInput class="d-none" id="uploadPhotos" multiple type="file" [accept]="fileTypes.imageExtensions"
              (change)="handleSelectedFiles($event)" />
            <button mat-raised-button type="button" (click)="chooseFiles();">Upload Photos</button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col align-content-center text-center"><span class="text-faded">or drag them in.</span></div>
        </div>
      </div>
      <div *ngIf="isProcessing" class="image-margin">
        <div class="col align-content-center">
          <p class="text-faded">Please wait while your photos are uploaded...</p>
        </div>
      </div>
    </ng-template>
  </ngx-file-drop>
</div>

<div *ngIf="statuses && statuses.length > 0">
  <ng-container *ngFor="let status of statuses">
    <div class="p-2">
      <div>{{status.file.name}}</div>
      <div>
        <mat-progress-bar mode="determinate" [value]="status.progress | async" />
      </div>
    </div>
    <mat-divider />
  </ng-container>
</div>
