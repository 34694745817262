<div>
  <div *ngIf="items?.length">
    <div class="fx-row y-center fx-gap-md">
      <div>
        <lc-checkbox *ngIf="selectEnabled && !isProduct" class="m-0" label="SELECT ALL" [(ngModel)]="allSelected" (toggled)="toggleAll()" />
      </div>

      <button *ngIf="deleteEnabled && !isProduct" class="text-faded" id="delete-selected-multimedia-items" mat-button [disabled]="noneSelected" (click)="deleteSelected()">
        <div class="fx-row x-center y-center fx-gap-xs">
          <mat-icon>delete</mat-icon>
          <span>Delete Selected</span>
        </div>
      </button>
    </div>

    <!-- Table -->
    <div class="table-container mat-elevation-z1">
      <div class="table-wrapper">
        <table mat-table [class.no-header]="!showHeaders" [dataSource]="viewModels">
          <!-- Code column -->
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef id="selection-header" mat-header-cell></th>
            <td *matCellDef="let vm; let i = index" class="selection-cell" mat-cell>
              <lc-checkbox *ngIf="!isProduct" class="mr-2" [idPrefix]="'multimedia-title-' + i" [(ngModel)]="vm.isSelected" (toggled)="onSelectionChanged(i)" />
              <label *ngIf="isProduct" class="form-check-label mr-2" [class.no-label]="vm.item.title == null" [id]="i + '-mm-label'">
                <input class="mm-radio-button" type="radio" [checked]="vm.isSelected" [id]="i + '-mm-input'" [name]="name" (click)="onMouseClick(i)"/>
              </label>
            </td>
          </ng-container>

          <!-- Name column -->
          <ng-container matColumnDef="name">
            <th *matHeaderCellDef id="name-header" mat-header-cell>Name</th>
            <td *matCellDef="let vm; let i = index" class="name-cell" mat-cell>
              {{vm.item.title}}
            </td>
          </ng-container>

          <!-- Link column -->
          <ng-container matColumnDef="link">
            <th *matHeaderCellDef id="link-header" mat-header-cell>Link</th>
            <td *matCellDef="let vm; let i = index" class="link-cell" mat-cell>
              <a target="new" [href]="vm.item.uri" [id]="'uri-multimedia-item-list-' + i">{{vm.item.uri}}</a>
            </td>
          </ng-container>

          <!-- Date column -->
          <ng-container matColumnDef="date">
            <th *matHeaderCellDef id="date-header" mat-header-cell>Date</th>
            <td *matCellDef="let vm; let i = index" class="date-cell" mat-cell>
              {{vm.item.audit.updatedAt | date: 'MM/dd/yyyy HH:mm'}}
            </td>
          </ng-container>

          <!-- Agent column -->
          <ng-container matColumnDef="agent">
            <th *matHeaderCellDef id="agent-header" mat-header-cell>Agent</th>
            <td *matCellDef="let vm; let i = index" class="agent-cell" mat-cell>
              {{vm.item.audit.updatedByFullName}}
            </td>
          </ng-container>

          <!-- Actions column -->
          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef id="actions-header" mat-header-cell>Actions</th>
            <td *matCellDef="let vm; let i = index" class="actions-cell" mat-cell>
              <button *ngIf="editEnabled && !isProduct" class="xs edit-multimedia-item" color="default" mat-icon-button  [id]="'edit-multimedia-item-list-' + i" (click)="open(edit, vm.item)">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="rows" mat-header-row></tr>
          <tr *matRowDef="let vm; columns: rows;" mat-row></tr>
        </table>
      </div>
    </div>
  </div>


  <ng-template #edit>
    <lc-base-dialog id="edit-dialog" title="Edit" (actionClicked)="onCancel()">
      <ng-template #content>
        <lc-multi-media-item-form idPrefix="update" [formGroup]="formGroup" />
      </ng-template>

      <ng-template #actions>
        <div class="fx-row x-end fx-gap-xs">
          <button id="multimedia-cancel-button" mat-raised-button type="button" (click)="onCancel()">Cancel</button>
          <button color="primary" id="multimedia-update-button" mat-raised-button type="button" [disabled]="!formGroup.valid" (click)="doUpdate()">Update</button>
        </div>
      </ng-template>
    </lc-base-dialog>
  </ng-template>
</div>
