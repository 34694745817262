<h5 *ngIf="photoType"><span class="text-capitalize">{{photoType.toLowerCase()}}</span> photos</h5>
<span *ngIf="photoType === 'GALLERY'">By default all photos below will be included in the property website photo
  gallery, with those marked as "Favorites" displaying first. Select those you would like to exclude from the website.</span>
<div *ngIf="!productCode" class="photo-toolbar fx-row y-center" [class.x-space-between]="showMenu">

  <div *ngIf="showMenu" class="fx-row y-center">

    <!-- Select All Checkbox-->
    <button class="text-faded bg-transparent" mat-flat-button [disableRipple]="true">
      <lc-checkbox *ngIf="selectEnabled" class="m-0" idPrefix="select-all" label="Select All" [(ngModel)]="isAllSelected" (toggled)="onSelectAll()" />
    </button>

    <!-- Collapsed menu on smaller screens -->
    <div *lcScreenSize="['small', 'medium']">
      <button aria-label="photo upload menu" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <!-- Download All Links-->
      <mat-menu #menu="matMenu">
        <ng-container *ngIf="downloadEnabled">
          <button class="text-faded" id="hi-res-download" mat-menu-item [disabled]="!anySelected || isHiResDwldWaiting" (click)="onDownloadSelected('hi')">
            <div class="fx-row y-center fx-gap-xs">
              <ng-container *ngIf="!isHiResDwldWaiting; else spinner">
                <mat-icon>get_app</mat-icon>
              </ng-container>
              <span>Hi Res</span>
            </div>
          </button>

          <button class="text-faded" id="low-res-download" mat-menu-item [disabled]="!anySelected || isLowResDwldWaiting" (click)="onDownloadSelected('lo')">
            <div class="fx-row y-center fx-gap-xs">
              <ng-container *ngIf="!isLowResDwldWaiting; else spinner">
                <mat-icon>get_app</mat-icon>
              </ng-container>
              <span>Lo Res</span>
            </div>
          </button>
        </ng-container>

        <!-- Delete All Button -->
        <button *ngIf="deleteEnabled" class="text-faded" id="delete-selected" mat-menu-item [disabled]="!anySelected" (click)="onDeleteSelected()">
          <div class="fx-row y-center fx-gap-xs">
            <mat-icon>delete</mat-icon>
            <span>Delete Selected</span>
          </div>
        </button>

        <!-- Favorites Filter -->
        <button *ngIf="favoritesEnabled" class="text-faded" id="favorites-filter" mat-menu-item (click)="onToggleFavorites()">
          <div class="fx-row y-center fx-gap-xs">
            <mat-icon
              matBadgeSize="small"
              [color]="showFavorites ? 'primary' : null"
              [matBadge]="numberOfFavorites"
              [matBadgeHidden]="!numberOfFavorites"
            >
              favorite
            </mat-icon>
            <span>Favorites</span>
          </div>
        </button>

      </mat-menu>

    </div>

    <div *lcScreenSize="['large']" class="fx-row y-center fx-gap">
      <ng-container *ngIf="downloadEnabled">
        <div class="fx-row y-center">
          <button class="text-faded" id="hi-res-download" mat-button [disabled]="!anySelected || isHiResDwldWaiting" (click)="onDownloadSelected('hi')">
            <div class="fx-row y-center fx-gap-sm">
              <ng-container *ngIf="!isHiResDwldWaiting; else spinner">
                <mat-icon>get_app</mat-icon>
              </ng-container>
              <div>Hi Res</div>
            </div>
          </button>
        </div>

        <div class="fx-row y-center">
          <button class="text-faded" id="low-res-download" mat-button [disabled]="!anySelected || isLowResDwldWaiting" (click)="onDownloadSelected('lo')">
            <div class="fx-row y-center fx-gap-sm">
              <ng-container *ngIf="!isLowResDwldWaiting; else spinner">
                <mat-icon>get_app</mat-icon>
              </ng-container>
              <div>Lo Res</div>
            </div>
          </button>
        </div>
      </ng-container>

      <!-- Delete All Button -->
      <div class="fx-row y-center">
        <button *ngIf="deleteEnabled" class="text-faded" id="delete-selected" mat-button [disabled]="!anySelected" (click)="onDeleteSelected()">
          <div class="fx-row y-center fx-gap-sm">
            <mat-icon>delete</mat-icon>
            <div>Delete Selected</div>
          </div>
        </button>
      </div>

      <!-- Favorites Filter -->
      <div class="fx-row y-center">
        <button *ngIf="favoritesEnabled" class="text-faded" id="favorites-filter" mat-button (click)="onToggleFavorites()">
          <div class="fx-row y-center fx-gap-sm">
            <mat-icon
              matBadgeSize="small"
              [color]="showFavorites ? 'primary' : null"
              [matBadge]="numberOfFavorites"
              [matBadgeHidden]="!numberOfFavorites"
            >
              favorite
            </mat-icon>
            <div>Favorites</div>
          </div>
        </button>
      </div>

    </div>
  </div>

  <!-- Grid Layout Toggle-->
  <div class="fx-row y-center">
    <button class="text-faded" id="set-4x4-grid-button" mat-icon-button (click)="useFourWideGrid = true">
      <mat-icon>view_comfy</mat-icon>
    </button>
    <button class="text-faded" id="set-2x2-grid-button" mat-icon-button (click)="useFourWideGrid = false">
      <mat-icon>view_module</mat-icon>
    </button>
  </div>

</div>
<hr style="margin-top: 5px; margin-bottom: 5px;">
<div *ngIf="(aiFeatureIsEnabled$ | async) && (AppService.isLCCoordinatorApp || AppService.isLCAgentApp) && aiEnabled"
     class="fx-row x-space-between y-center fx-gap-lg" style="padding: 10px 10px 20px;">
  <div class="fx-row fx-gap-sm y-center">
    <mat-icon>tips_and_updates</mat-icon>
    <h5 *ngIf="!captionsGenerated" style="margin: 0;"><strong>Listing Concierge Pro Tip:</strong> Favorite up to 10 photos and click Generate to create AI photo captions that will be used to help create your Property Description.</h5>
    <h5 *ngIf="captionsGenerated" style="margin: 0;"><strong>Listing Concierge Pro Tip:</strong> Review/Edit your photo captions to create a better AI generated Property Description. To edit, click on your favorite photos.</h5>
  </div>
  <button color="primary" mat-raised-button style="margin: 0; min-width: 120px;" type="button" [disabled]="generating || getAiRequests() >= getAiMaxRequests() || (numberOfFavorites === 0)"
          (click)="generateTagsAndCaptions()"
  >
    Generate
  </button>
</div>

<!-- Drag and Drop Grid -->
<!-- LC-2676 --->
<!-- [itemClass]="(productCode ? 'col-4' : useFourWideGrid ? 'col-3' : 'col-6') -->
<!-- if a productCode is passed in then the component is used at a product services level and photo thumbnails need to be larger -->
<lc-drag-n-drop
  *ngIf="!usingCdk"
  containerClass="row"
  containerId="photoList"
  [canDrag]="canDrag()"
  [imagesProcessing]="imagesProcessing"
  [itemClass]="useFourWideGrid ? 'manage-photo-grid' : 'product-grid'"
  [items]="displayedViewModels"
  (dragChanged)="isDragging = $event"
  (reorder)="onDragAndDrop($event)">
  <ng-template let-index="index" let-item="item">
    <lc-photo
      [controlPrefix]="'photo-' + (index + 1)"
      [deleteStyle]="photoType"
      [viewModel]="item"
      (click)="onOpenGallery(index)"
      (delete)="onDelete($event)"
      (hide)="onHide()"
      (reorder)="onReorder($event.viewModel, $event.order)"
      (select)="onSelect($event)"
      (toggleFavorites)="onToggleFavorite($event)"
    />
  </ng-template>
</lc-drag-n-drop>

<lc-drag-n-drop-cdk
  *ngIf="usingCdk"
  containerClass="row"
  containerId="photoList"
  [additionalDrops]="additionalDrops"
  [canDrag]="canDrag()"
  [canDrop]="canDrop()"
  [itemClass]="useFourWideGrid ? 'manage-photo-grid' : 'product-grid'"
  [items]="displayedViewModels"
  [verticalScrollbar]="verticalScrollbar"
  (reorder)="onDragAndDrop($event)">
  <ng-template let-index="index" let-item="item">
    <lc-photo
      [deleteStyle]="photoType"
      [id]="'photo-' + (index + 1)"
      [viewModel]="item"
      (click)="onOpenGallery(index)"
      (delete)="onDelete($event)"
      (hide)="onHide()"
      (reorder)="onReorder($event.viewModel, $event.order)"
      (select)="onSelect($event)"
      (toggleFavorites)="onToggleFavorite($event)" />
  </ng-template>
</lc-drag-n-drop-cdk>


<!-- Photo download spinner -->
<ng-template #spinner>
  <mat-spinner diameter="24" />
</ng-template>
